import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { CommentIcon } from '../icons/comment-icon';
import { LikeHollowIcon } from '../icons/like-hollow-icon';
import withTranslate from '../../../common/components/with-translate/with-translate';
import styles from './post-list-classic-header.scss';
import { ViewCountIcon } from '../icons/view-count-icon';
import { connect } from '../../../common/components/runtime-context';
import flowRight from 'lodash/flowRight';
import { getIsForumWidthLessThan } from '../../../common/store/basic-params/basic-params-selectors';
import { PostBreakpoints } from '../responsive-listener';

const PostListClassicHeader = ({
  t,
  isViewsCountEnabled,
  pagination,
  showCommentsCount,
  showLikesCount,
  showViewsCount,
}) => (
  <thead>
    <tr className={styles.row}>
      <th className={classNames(styles.titleHeader, styles.left, 'forum-text-color')}>
        {pagination}
      </th>
      {showCommentsCount && (
        <th className={styles.iconHeader}>
          <CommentIcon className="forum-icon-stroke" title={t('post-list-classic.comments')} />
        </th>
      )}
      {showLikesCount && (
        <th className={styles.iconHeader}>
          <LikeHollowIcon className="forum-icon-stroke" title={t('post-list-classic.likes')} />
        </th>
      )}
      {isViewsCountEnabled && showViewsCount && (
        <th className={styles.iconHeader}>
          <ViewCountIcon className="forum-icon-fill" title={t('post-list-classic.views')} />
        </th>
      )}
      <th className={classNames(styles.timeAgoHeader, styles.left, 'forum-text-color')}>
        {t('post-list-classic.recent-activity')}
      </th>
      <th className={styles.moreButtonHeader}>
        <span className={styles.a11yOnly}>{t('post-list-classic.table-header-more-button')}</span>
      </th>
    </tr>
  </thead>
);

PostListClassicHeader.propTypes = {
  t: PropTypes.func.isRequired,
  isViewsCountEnabled: PropTypes.bool.isRequired,
  pagination: PropTypes.any,
};

export const IconColumnWidth = 72;

const mapRuntimeToProps = state => ({
  showViewsCount: !getIsForumWidthLessThan(state, PostBreakpoints.sm + IconColumnWidth),
  showLikesCount: !getIsForumWidthLessThan(state, PostBreakpoints.sm),
  showCommentsCount: !getIsForumWidthLessThan(state, PostBreakpoints.sm - IconColumnWidth),
});

export default flowRight(withTranslate, connect(mapRuntimeToProps))(PostListClassicHeader);
