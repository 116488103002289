import { getPost } from './post-selectors';
import { getPaginationConfig } from '../constants/comments-pagination-config';

const getPagination = (state, postId) => {
  const postData = getPost(state, postId);

  return (
    state.commentsPagination[postId] || {
      commentsPaginationConfig: getPaginationConfig(postData),
      pagerState: { type: 'NONE' },
    }
  );
};

export const getCommentsPaginationConfig = (state, postId) =>
  getPagination(state, postId)?.commentsPaginationConfig || {};

export const getCommentsPaginationState = (state, postId) =>
  getPagination(state, postId)?.resourcePaginationState || {};

export const getPagerStateItems = (state, postId) =>
  getPagination(state, postId)?.pagerState?.items || [];
