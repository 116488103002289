import { flowRight } from 'lodash';
import React, { Component } from 'react';
import { connect } from '../../../common/components/runtime-context';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { COVER_TYPE_IMAGE } from '../../constants/cover-types';
import MoreButton from '../../components/more-button';
import CategoryActions from '../category-actions';
import Link from '../../components/link/internal-link';
import withFontClassName from '../../hoc/with-font-class-name';
import withTranslate from '../../../common/components/with-translate/with-translate';
import styles from './subcategory-list-item-desktop.scss';
import FollowButton from '../../components/follow-button/follow-button';
import {
  isCacheableRendering,
  getIsForumWidthLessThan,
  getLanguage,
} from '../../../common/store/basic-params/basic-params-selectors';
import { REDUCERS } from '../../../common/components/runtime-context/reducers';
import withPermissions from '../../hoc/with-permissions';
import { ImgBackground } from '@wix/communities-image-lib';
import { isPrivate, SORT_BY_NEWEST } from '@wix/communities-forum-client-commons';
import { LockEmptyIcon } from '../../components/icons/lock-empty-icon';
import { CurrencySignIcon } from '../../components/icons/currency-sign-icon';
import { hasCategoryPaidPlans } from '../../selectors/paid-plans-selectors';
import FollowNotification from '../../components/follow-notification';
import { ViewCountIcon } from '../../components/icons/view-count-icon';
import { CommentIconSmall } from '../../components/icons/comment-icon-small';
import NewContentIndicator from '../../components/new-content-indicator';
import { PostBreakpoints } from '../../components/responsive-listener';

import CounterNumber from '../../components/counter-number';

export class SubcategoryListItemDesktop extends Component {
  hasImage = () => this.props.category.coverType === COVER_TYPE_IMAGE;

  renderImageIfNeeded = () => {
    const image = this.hasImage() && (
      <ImgBackground
        className={styles.image}
        src={this.props.category.cover}
        width={200}
        height={120}
      />
    );
    return image && this.linkToPosts(image);
  };

  renderContentBlock = () => {
    const { titleFontClassName, category, hasCategoryPP } = this.props;
    let categoryBadge = null;
    if (isPrivate(category)) {
      const Icon = hasCategoryPP ? CurrencySignIcon : LockEmptyIcon;
      categoryBadge = (
        <span className={styles.categoryBadgeWrapper}>
          <Icon className="forum-icon-fill" />
        </span>
      );
    }
    const titleText = (
      <h3
        className={classNames(styles.title, titleFontClassName)}
        data-hook="subcategories-list-item__title"
      >
        <span>{category.label}</span> {categoryBadge}
      </h3>
    );
    return (
      <div className={styles.contentBlock}>
        {this.linkToPosts(titleText, 'subcategories-list-item__link')}
        <span
          style={{ width: this.hasImage() ? '315px' : '443px' }}
          className={styles.description}
          data-hook="subcategories-list-item__description"
        >
          {category.description}
        </span>
      </div>
    );
  };

  renderNumbersElement = (number, text, dataHook) => (
    <div className={styles.numberElement} data-hook={dataHook}>
      <span className={classNames(styles.numberElementHeader, this.props.titleFontClassName)}>
        {number}
      </span>
      <span>{text}</span>
    </div>
  );

  handlePostFetch = sort => () => {
    const { category, fetchCategoryPosts, resetPostTypeFilter, setPostsPageSorting } = this.props;
    sort && setPostsPageSorting(sort, { dontReportBI: true });
    resetPostTypeFilter();
    fetchCategoryPosts({ categoryId: category._id, page: 1, sort });
  };

  linkToPosts = (children, dataHook, sort) => {
    const { category } = this.props;
    return (
      <Link
        className={classNames('forum-text-color', 'forum-link-hover-color')}
        to={`/${category.slug}`}
        onMouseDown={this.handlePostFetch(sort)}
        data-hook={dataHook}
      >
        {children}
      </Link>
    );
  };

  render() {
    const {
      category,
      contentFontClassName,
      isViewsCountEnabled,
      showViewCount,
      t,
      can,
      shouldWrap,
    } = this.props;
    const shouldShowMoreButton = can('edit', 'category', category);

    const moreButton = (
      <MoreButton data-hook="subcategories-list-item__category-actions">
        <CategoryActions hideFollowButton category={category} />
      </MoreButton>
    );
    const followButton = (
      <FollowButton
        category={category}
        className={shouldShowMoreButton ? styles.followButton : ''}
        data-hook="subcategories-list-item__follow-button"
      >
        <FollowNotification isFollowing={category.isSubscribed} />
      </FollowButton>
    );
    const categoryActions = (
      <div className={classNames({ [styles.fullRow]: shouldWrap })}>
        <div className={styles.actionsContainer}>
          {followButton}
          {shouldShowMoreButton && moreButton}
        </div>
      </div>
    );
    const categoryContent = (
      <div className={styles.main}>
        <div className={classNames(styles.contentPreview, 'forum-text-color')}>
          {this.renderImageIfNeeded()}
          {this.renderContentBlock()}
        </div>

        <div className={styles.mainStats}>
          <div className={classNames(styles.numbers)}>
            {isViewsCountEnabled && (
              <div
                className={styles.numberElementWithIcon}
                data-hook="subcategories-list-item__total-views"
              >
                <ViewCountIcon
                  title={t('subcategory-list-item.views')}
                  className="forum-icon-fill"
                />
                <span className={classNames(styles.numberElementText)}>
                  {showViewCount ? (
                    <CounterNumber entityId={category._id} viewCount />
                  ) : (
                    <span>&nbsp;</span>
                  )}
                </span>
              </div>
            )}

            <div
              className={styles.numberElementWithIcon}
              data-hook="subcategories-list-item__total-posts"
            >
              <CommentIconSmall
                title={t('subcategory-list-item.posts', 'subcategories-list-item__total-posts')}
                className="forum-icon-fill"
              />
              <span className={classNames(styles.numberElementText)}>
                <CounterNumber
                  initialValue={category.totalPosts}
                  entityId={category._id}
                  totalPosts
                />
              </span>
            </div>
          </div>
          {!!category.unseenPostsCount && (
            <div className={styles.recentActivity}>
              {this.linkToPosts(
                <NewContentIndicator count={category.unseenPostsCount} />,
                null,
                SORT_BY_NEWEST,
              )}
            </div>
          )}
        </div>
      </div>
    );

    return (
      <div
        className={classNames(styles.container, contentFontClassName, {
          [styles.containerWrap]: shouldWrap,
        })}
        data-hook="subcategories-list-item"
      >
        {categoryContent}

        {categoryActions}
      </div>
    );
  }
}

SubcategoryListItemDesktop.propTypes = {
  t: PropTypes.func,
  category: PropTypes.object,
  titleFontClassName: PropTypes.string,
  contentFontClassName: PropTypes.string,
  isViewsCountEnabled: PropTypes.bool,
  showViewCount: PropTypes.bool,
  hasCategoryPP: PropTypes.bool,
  fetchCategoryPosts: PropTypes.func,
  resetPostTypeFilter: PropTypes.func,
  setPostsPageSorting: PropTypes.func,
  can: PropTypes.func,
};

const mapRuntimeToProps = (state, { category }, actions) => ({
  fetchCategoryPosts: actions.fetchCategoryPosts,
  resetPostTypeFilter: actions.resetPostTypeFilter,
  setPostsPageSorting: actions.setPostsPageSorting,
  showViewCount: !isCacheableRendering(state),
  hasCategoryPP: hasCategoryPaidPlans(state, category.groups),
  language: getLanguage(state),
  shouldWrap: getIsForumWidthLessThan(state, PostBreakpoints.xs),
});

export default flowRight(
  connect(mapRuntimeToProps, [REDUCERS.CATEGORIES, REDUCERS.BASIC_PARAMS]),
  withTranslate,
  withFontClassName,
  withPermissions,
)(SubcategoryListItemDesktop);
