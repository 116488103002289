import { URI as uri, buildPaginationRequestParams } from '@wix/communities-forum-client-commons';
import { noop } from 'lodash';
import createAction from '../../common/services/create-action';

import { getCommentsPerPage } from '../constants/pagination';
import { actionPromiseCache } from '../services/action-promise-cache';
import { getIsMobile } from '../../common/store/basic-params/basic-params-selectors';
import { fetchCounters } from './fetch-counters';

export const FETCH_POST_PAGE_DATA_REQUEST = 'FETCH_POST_PAGE_DATA_REQUEST';
export const FETCH_POST_PAGE_DATA_SUCCESS = 'FETCH_POST_PAGE_DATA_SUCCESS';
export const FETCH_POST_PAGE_DATA_FAILURE = 'FETCH_POST_PAGE_DATA_FAILURE';

export const fetchPostPageDataRequest = createAction(FETCH_POST_PAGE_DATA_REQUEST);
export const fetchPostPageDataSuccess = createAction(
  FETCH_POST_PAGE_DATA_SUCCESS,
  payload => payload,
  (payload, meta) => meta,
);
export const fetchPostPageDataFailure = createAction(FETCH_POST_PAGE_DATA_FAILURE);

export const fetchPostPageData = actionPromiseCache(
  (postSlug, page = 1) => {
    return (dispatch, getState, { request }) => {
      dispatch(fetchPostPageDataRequest({ postSlug }));

      const params = buildPaginationRequestParams(
        page,
        getCommentsPerPage(getIsMobile(getState())),
      );
      const promise = request(uri(`/posts/${postSlug}/data-view-v2`).query(params));
      return promise
        .then(result => {
          if (result.post) {
            // In some cases corrupted content is created and Ricos fails to handle it
            // Added default values for Ricos content.
            result.post.content = {
              entityMap: {},
              blocks: [],
              ...result.post.content,
            };

            dispatch(fetchCounters({ postIds: [result.post._id] }));
            return dispatch(fetchPostPageDataSuccess({ result, postSlug, page }));
          } else {
            return dispatch(fetchPostPageDataFailure({ postSlug }));
          }
        })
        .catch(noop)
        .then(() => promise);
    };
  },
  { cacheKeys: [0, 1], cacheTime: 600, ignoreResolve: true },
);
